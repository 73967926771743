<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class="o-container-content">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
          <v-menu
            left
            bottom
            
          >
            <template v-slot:activator="{on, attrs}">
              <v-btn                
                color="lighten-3 text-uppercase bg-primary mt-1"
                small
                depressed
                v-bind="attrs"
                butt 
                v-on="on"
              >
                {{source}}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list style="cursor: pointer">
              <v-list-item>
                <v-list-item-title @click="source='All-Time'; reset();">
                  All-Time
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="source='7 Days'; reset();">
                  7 Days
                </v-list-item-title>
              </v-list-item> 
            </v-list>
          </v-menu>

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="25vw"
            :visible="showFilter"
            @on-item-search="reset"
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                class="pa-0 ma-0"
                style="margin-left:-10px !important"
              >
                <o-check-box
                  v-model="useTextBox"
                  :busy="dropBusy"
                  label="Use Text"
                />
              </v-col>
            </v-row>
            <v-row v-if="!useTextBox" class="pa-0 ma-0">
              <v-col cols="12">
                <o-combo-box
                  v-model="account_id"
                  :busy="dropBusy"
                  label="Account"
                  :items="accounts"
                  @on-change="getAdvertisers()"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="advertiser_id"
                  :busy="dropBusy"
                  label="Advertisers"
                  :items="advertisers"
                  @on-change="getCampaigns()"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="campaign_id"
                  :busy="dropBusy"
                  label="Campaigns"
                  :items="campaigns"
                  @on-change="getLineItems()"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="line_item_id"
                  :busy="dropBusy"
                  label="Line Items"
                  :items="line_items"
                />
              </v-col>
            </v-row>
            <v-row v-if="useTextBox" class="pa-0 ma-0">
              <v-col cols="12">
                <o-input
                  v-model="account_id"
                  :busy="dropBusy"
                  label="Account"
                  :items="accounts"
                />
              </v-col>
              <v-col cols="12">
                <o-input
                  v-model="advertiser_id"
                  :busy="dropBusy"
                  label="Advertisers"
                  :items="advertisers"
                />
              </v-col>
              <v-col cols="12">
                <o-input
                  v-model="campaign_id"
                  :busy="dropBusy"
                  label="Campaigns"
                  :items="campaigns"
                />
              </v-col>
              <v-col cols="12">
                <o-input
                  v-model="line_item_id"
                  :busy="dropBusy"
                  label="Line Items"
                  :items="line_items"
                />
              </v-col>
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider />

      <v-skeleton-loader
        v-if="busy"
        :loading="busy"
        type="table"
        transition="v-scroll-x-transition"
      />
      <v-card-text>
        <o-table
          v-if="data && !busy"
          class="pa-0 ma-0 pl-10 pr-10"
          type="array"
          :data="data"
          :height="getHeight(342)"
          :fixed-header="true"
          :no-count="true"
          :headers="[
            {
              text: 'DATE',
              align: 'start',
              class: 'f2',
              width: '120px',
              sortable: false,
              value: 'bid_date',
              type: 'text',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_impressions',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_impressions',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_impressions',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2 border-b-2 border-gray-600',
              sortable: false,
              value: 'mv12_impressions',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_clearing_price',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_clearing_price',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_clearing_price',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_clearing_price',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_spends',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_spends',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_spends',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_spends',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_bids',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_bids',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_bids',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_bids',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_bid_price',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_bid_price',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_bid_price',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_bid_price',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_wins',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_wins',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_wins',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_wins',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_starts',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_starts',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_starts',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_starts',
              type: 'slot',
            },
            {
              text: 'REDSHIFT',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'red_completes',
              type: 'slot',
            },
            {
              text: '72 HOURS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'main_completes',
              type: 'slot',
            },
            {
              text: '2 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv2_completes',
              type: 'slot',
            },
            {
              text: '12 MONTHS',
              align: 'end',
              class: 'f2',
              sortable: false,
              value: 'mv12_completes',
              type: 'slot',
            },
            {
              text: '',
              align: 'start',
              class: 'f2',
              sortable: false,
              value: 'active',
              type: 'slot',
            },
          ]"
        >
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader f1" scope="col">
                  &nbsp;
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>IMPRESSIONS</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>CLEARING PRICE</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>SPENDS</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>BIDS</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>BID PRICE</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>WINS</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>STARTS</span>
                </th>
                <th
                  role="columnheader f1"
                  scope="col"
                  colspan="4"
                  class="text-center"
                >
                  <span>COMPLETES</span>
                </th>
                <th role="columnheader f1" scope="col">
                  &nbsp;
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key.indexOf('impressions') > -1"
              class="text-right"
            >
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_impressions,
                    row.prop.item.main_impressions,
                    row.prop.item.mv2_impressions,
                    row.prop.item.mv12_impressions
                  ),
                ]"
                >{{ getInteger(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div
              v-if="row.prop.key.indexOf('clearing_price') > -1"
              class="text-right"
            >
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_clearing_price,
                    row.prop.item.main_clearing_price,
                    row.prop.item.mv2_clearing_price,
                    row.prop.item.mv12_clearing_price
                  ),
                ]"
                >{{ getFloat(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div v-if="row.prop.key.indexOf('bids') > -1" class="text-right">
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_bids,
                    row.prop.item.main_bids,
                    row.prop.item.mv2_bids,
                    row.prop.item.mv12_bids
                  ),
                ]"
                >{{ getInteger(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div
              v-if="row.prop.key.indexOf('bid_price') > -1"
              class="text-right"
            >
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_bid_price,
                    row.prop.item.main_bid_price,
                    row.prop.item.mv2_bid_price,
                    row.prop.item.mv12_bid_price
                  ),
                ]"
                >{{ getFloat(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div v-if="row.prop.key.indexOf('spends') > -1" class="text-right">
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_spends,
                    row.prop.item.main_spends,
                    row.prop.item.mv2_spends,
                    row.prop.item.mv12_spends
                  ),
                ]"
                >{{ getFloat(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div v-if="row.prop.key.indexOf('wins') > -1" class="text-right">
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_wins,
                    row.prop.item.main_wins,
                    row.prop.item.mv2_wins,
                    row.prop.item.mv12_wins
                  ),
                ]"
                >{{ getInteger(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div v-if="row.prop.key.indexOf('starts') > -1" class="text-right">
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_starts,
                    row.prop.item.main_starts,
                    row.prop.item.mv2_starts,
                    row.prop.item.mv12_starts
                  ),
                ]"
                >{{ getInteger(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div
              v-if="row.prop.key.indexOf('completes') > -1"
              class="text-right"
            >
              <span
                :class="[
                  compare(
                    row.prop.item.bid_date,
                    row.prop.item.red_completes,
                    row.prop.item.main_completes,
                    row.prop.item.mv2_completes,
                    row.prop.item.mv12_completes
                  ),
                ]"
                >{{ getInteger(row.prop.item[row.prop.key]) }}</span
              >
            </div>
            <div v-if="row.prop.key === 'active'" class="text-right">
              <o-button
                v-if="Boolean(row.prop.item[row.prop.key]) && row.prop.item.red_impressions > 0"
                color="primary"
                icon="mdi-sync"
                type="icon"
                @on-click="refill(row.prop.item)"
              />
            </div>
          </template>
        </o-table>
      </v-card-text>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "TrafficStatsChecker",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Traffic Stats",
  },
  data() {
    return {
      title: "Traffic Stats",
      showFilter: false,
      busy: false,
      dropBusy: false,
      accounts: [],
      advertisers: [],
      campaigns: [],
      line_items: [],
      source: '7 Days',
      data: [],
      valid: false,
      account_id: 0,
      advertiser_id: 0,
      useTextBox: false,
      campaign_id: 0,
      line_item_id: 0, //202694, //'202902',
      chart: {
        series: [],
        chartOptions: {
          chart: {
            type: "line",
            height: 350,

            toolbar: {
              show: false,
            },
          },
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          stroke: {
            width: 7,
            curve: "smooth",
          },
          xaxis: {
            categories: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
            ],
            title: {
              text: "",
            },
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            title: {
              text: "",
            },
            min: 5,
            max: 40,
          },
          markers: {
            size: 5,
            hover: {
              size: 7,
            },
          },
          grid: {
            borderColor: "transparent",
            row: {
              colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
              opacity: 0,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  mounted() {
    this.getData(`rest/accounts`, null).then((response) => {
      this.accounts.push({ id: 0, name: "All" });
      response.data.forEach((d) => {
        this.accounts.push({ id: d.id, name: d.account_name });
      });
      this.advertisers.push({ id: 0, name: "All" });
      this.campaigns.push({ id: 0, name: "All" });
      this.line_items.push({ id: 0, name: "All" });
    });
  },
  methods: {
    reset() {
      this.busy = true;
      this.showFilter = false;
      setTimeout(async () => {

        const parameters = {
          account_id: this.account_id,
          advertiser_id: this.advertiser_id,
          campaign_id: this.campaign_id,
          line_item_id: this.line_item_id
        };
        const source = this.source === '7 Days'? 'check_traffic_stats_past7days' : 'check_traffic_stats';
        await this.postData(
          `/rest/intranet/execute?time=${new Date().getTime()}`,
          {
            topic: "",
            name: source,
            params: parameters,
          }
        )
        .then((response) => {
          if (response.data != {}) {
            const data = _.cloneDeep(response.data.data).map((d) => {
              const active =
                d.main_impressions != d.mv2_impressions ||
                d.main_clearing_price != d.mv2_clearing_price ||
                d.main_bids != d.mv2_bids ||
                d.main_bid_price != d.mv2_bid_price ||
                d.main_spends != d.mv2_spends ||
                d.main_wins != d.mv2_wins ||
                d.main_starts != d.mv2_starts ||
                d.mv12_completes != d.mv2_completes ||
                d.main_impressions != d.mv12_impressions ||
                d.main_clearing_price != d.mv12_clearing_price ||
                d.main_bids != d.mv12_bids ||
                d.main_bid_price != d.mv12_bid_price ||
                d.main_spends != d.mv12_spends ||
                d.main_wins != d.mv12_wins ||
                d.main_starts != d.mv12_starts ||
                d.mv12_completes != d.mv12_completes;
              return { ...d, active };
            });
            this.data = data;
          } else {
            this.$refs.container.snackBar(
              `No record found.mdi-text-box-search-outline`,
              `red`,
              2000
            );
          } 
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
        });

      
      }, 200);
    },
    compare(bd, red, main, mv2, mv12) {
      const date = new Date(new Date().setDate(new Date().getDate() - 7));
      const bid_date = new Date(bd);

      const seventhDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      if (bid_date > seventhDay) {
        if (red != main || red != mv2 || red != mv12) return "text-red";
      } else {
        if (main != mv2 || main != mv12) return "text-red";
      }

      return "";
    },
    async refill(item) {
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are back filling <strong>${item.bid_date}</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.busy = true;
            await this.getData(
              `/rest/intranet/backfill-traffic-stats?date_to_fill=${item.bid_date}`,
              null
            )
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The date ${item.bid_date} was successfully back filled.
                `,
                  "green",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.busy = false;
                this.$refs.container.snackBar(
                  `
                Error running backfill: ${err.data.message}
                `,
                  "red",
                  2000
                );
              });
          }
        });
    },
    async getAdvertisers() {
      this.advertisers = [];
      if (this.account_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/advertisers?platform_id=1&account_id=${this.account_id}&sort_by=advertiser_name&order=ASC&page_size=5000&compact=1`,
          null
        ).then((response) => {
          this.advertisers.push({ id: 0, name: "All" });
          this.campaigns.push({ id: 0, name: "All" });
          this.line_items.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.advertisers.push({
              id: d.advertiser_id,
              name: d.advertiser_name,
            });
          });
          this.dropBusy = false;
        });
      } else {
        this.advertisers = [];
        this.advertisers.push({ id: 0, name: "All" });
        this.campaigns.push({ id: 0, name: "All" });
        this.line_items.push({ id: 0, name: "All" });
      }
    },
    async getCampaigns() {
      this.campaigns = [];
      if (this.advertiser_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/campaigns?platform_id=1&account_id=${this.account_id}&advertiser_id=${this.advertiser_id}&sort_by=campaign_name&order=ASC&page_size=500&compact=1`,
          null
        ).then((response) => {
          this.campaigns.push({ id: 0, name: "All" });
          this.line_items.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.campaigns.push({ id: d.campaign_id, name: d.campaign_name });
          });
          this.dropBusy = false;
        });
      } else {
        this.campaigns = [];
        this.campaigns.push({ id: 0, name: "All" });
        this.line_items.push({ id: 0, name: "All" });
      }
    },
    async getLineItems() {
      this.line_items = [];
      if (this.advertiser_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/line_items?platform_id=1&account_id=${this.account_id}&advertiser_id=${this.advertiser_id}&campaign_id=${this.campaign_id}&sort_by=line_item_name&order=ASC&page_size=500&compact=1`,
          null
        ).then((response) => {
          this.line_items.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.line_items.push({
              id: d.line_item_id,
              name: d.line_item_name,
            });
          });
          this.dropBusy = false;
        });
      } else {
        this.line_items = [];
        this.line_items.push({ id: 0, name: "All" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .f1 {
  top: 0px !important;
}
::v-deep .f2 {
  top: 28px !important;
}
</style>
