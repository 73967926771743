<template>
  <TrafficStatsChecker dialog-name="Traffic Stats Checker" />
</template>
<script>
import TrafficStatsChecker from '@/views/app/checker/TrafficStats'

export default {
  name: 'TrafficStatsWrapper',
  components: {
    TrafficStatsChecker
  }
}
</script>
